import { ARTICLE_LISTING } from "../../../constants/api";
import axiosInstance from "../../../helpers/axiosInstance";






export const getarticleListing=() => onResponse => {

    try {
        axiosInstance.get(ARTICLE_LISTING)
            .then((response) => {
                onResponse(response);
            })
            .catch((err) => {
                onResponse(err.response);
            });

    } catch (error) {

    }
};

export const getsinglearticleListing=(id) => onResponse => {

    try {
        axiosInstance.get(ARTICLE_LISTING+'?id='+id)
            .then((response) => {
                onResponse(response);
            })
            .catch((err) => {
                onResponse(err.response);
            });

    } catch (error) {

    }
};








