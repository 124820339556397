import React from 'react'
import Footer from './header/footer';
import Header from './header/header';

const Layout=({ children }) => {
    return (
        <React.Fragment>
            <Header />
            <main style={{ marginTop: "69px" }}>
                {children}
            </main>
            <Footer />
        </React.Fragment>
    )
}

export default Layout;