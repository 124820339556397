import React, { useEffect, useRef } from "react";
import "../assets/css/component-styles/articles.scss";
import Layout from "./layout";
import blog_img from "../assets/images/blog/banner.png";
import news1 from "../assets/images/home/news1.png";
import news2 from "../assets/images/home/news2.png";
import news3 from "../assets/images/home/news3.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import Slide from "react-reveal/Slide";
import {
  getarticleListing,
  getsinglearticleListing,
} from "../context/actions/articles";
import moment from "moment";

const Articles = () => {
  const { id } = useParams();
  const bottomRef = useRef(null);
  const navigate = useNavigate();
  const [update, setUpdate] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [articles, setArticles] = React.useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    console.log("hiiii");
    getarticleListing()((response) => {
      if (response && response.status) {
        setArticles(response?.data?.data);
      }
    });
    getsinglearticleListing(id)((response) => {
      if (response && response.status) {
        setData(response?.data?.data?.results[0]);
      }
    });
  }, [update]);

  const onClickNewArticle = (articleId) => {
    navigate("/articles/" + articleId);
    setUpdate(!update);
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top of the page
  };
  return (
    <React.Fragment>
      <Layout>
      <div ref={bottomRef}></div>
        <div className="articles">
          <div className="container">
            <div className="blog-img">
              <img src={data?.blog_image} alt="slider1" className="img-fluid" />
              {/* <div className="overlay"></div> */}
            </div>
          </div>
          <div className="main-content">
            <div className="container">
              <div className="sub-section">
                <Slide bottom>
                  <div className="row">
                    <div className="col-md-9">
                      <h4>{data?.title}</h4>
                    </div>
                    <div className="col-md-3">
                      <div className="t-right">
                        <p>{moment(data?.date).format("ll")}</p>
                        <h6>Posted by: {data?.posted_by}</h6>
                      </div>
                    </div>
                  </div>
                </Slide>
              </div>
              <hr className="hrline" />
              <div className="row">
                <Slide bottom>
                  <div className="col-12">
                    <div className="description">
                      <p> <div dangerouslySetInnerHTML={{__html: data?.description}}/></p>
                    </div>
                  </div>
                </Slide>
              </div>
              <hr className="hrline" />
              <div className="article-section">
                <div className="row">
                  <div className="col-12">
                    <div className="title">
                    {/* {articles.length>0 && */}
                      <h4>Recent Articles</h4>
                    {/* } */}
                    </div>
                  </div>
                </div>
              
                  <div className="row">
                    {articles?.results?.map(
                      (article, index) =>
                        article.id != id && (
                          <div className="col-lg-4 col-sm-6 pt-60" key={index}>
                            <div onClick={() => onClickNewArticle(article?.id)}>
                              <div className="article-wrapper">
                                <div className="img-wrapper">
                                  <img
                                    src={article?.blog_image}
                                    alt={article?.title}
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="content-wrapper">
                                  <h6>{article?.title}</h6>
                                  <h5> <div dangerouslySetInnerHTML={{__html: article?.description}}/></h5>
                                  <p className="date">
                                    {moment(article?.date).format("ll")}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                    )}
                    {/* <div className='col-lg-4 col-sm-6 pt-60'>
                                            <Link to=''>
                                                <div className='article-wrapper'>
                                                    <div className='img-wrapper'>
                                                        <img src={news2} alt='slider1' className='img-fluid' />
                                                    </div>
                                                    <div className='content-wrapper'>
                                                        <h6>Articles</h6>
                                                        <h5>Becker’s Hospital review recently
                                                            reported "Between increasing
                                                            pressures and mounting expens  pressures and mounting expens...</h5>
                                                        <p className='date'>FEB 21, 2023</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className='col-lg-4 col-sm-6 pt-60'>
                                            <Link to=''>
                                                <div className='article-wrapper'>
                                                    <div className='img-wrapper'>
                                                        <img src={news3} alt='slider1' className='img-fluid' />
                                                    </div>
                                                    <div className='content-wrapper'>
                                                        <h6>Articles</h6>
                                                        <h5>Becker’s Hospital review recently
                                                            reported "Between increasing
                                                            pressures and mounting expens  pressures and mounting expens...</h5>
                                                        <p className='date'>FEB 21, 2023</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className='col-lg-4 col-sm-6 pt-60'>
                                            <Link to=''>
                                                <div className='article-wrapper'>
                                                    <div className='img-wrapper'>
                                                        <img src={news1} alt='slider1' className='img-fluid' />
                                                    </div>
                                                    <div className='content-wrapper'>
                                                        <h6>Articles</h6>
                                                        <h5>Becker’s Hospital review recently
                                                            reported "Between increasing
                                                            pressures and mounting expens  pressures and mounting expens...</h5>
                                                        <p className='date'>FEB 21, 2023</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className='col-lg-4 col-sm-6 pt-60'>
                                            <Link to=''>
                                                <div className='article-wrapper'>
                                                    <div className='img-wrapper'>
                                                        <img src={news2} alt='slider1' className='img-fluid' />
                                                    </div>
                                                    <div className='content-wrapper'>
                                                        <h6>Articles</h6>
                                                        <h5>Becker’s Hospital review recently
                                                            reported "Between increasing
                                                            pressures and mounting expens  pressures and mounting expens...</h5>
                                                        <p className='date'>FEB 21, 2023</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div> */}
                  </div>
             
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default Articles;
