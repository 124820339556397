import React, { useEffect, useRef } from 'react'
import '../assets/css/component-styles/contact.scss';
import Layout from './layout';
import mail from '../assets/images/contact/mail.png'
import call from '../assets/images/contact/call.png'
import location from '../assets/images/contact/location.png'
import { Link } from 'react-router-dom';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import Slide from 'react-reveal/Slide';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import { SendEmail } from '../context/actions/contactus/index';
import { BsChevronDoubleDown } from 'react-icons/bs';
const Contact=() => {
    const bottomRef=useRef(null);
    const myRef = useRef(null);
    useEffect(() => {
        bottomRef.current?.scrollIntoView({ block: 'start' });
    }, [bottomRef.current]);


        // API
        const formik=useFormik({
            initialValues: {
                name: "",
                company_name: "",
                email: "",
                number: "",
                message: "",
    
            },
            validationSchema: Yup.object().shape({
                name: Yup.string()
                    .required('Required'),
                company_name: Yup.string()
                        .required('Required'),
                email: Yup.string().matches(/(\<|^)[\w\d._%+-]+@(?:[\w\d-]+\.)+(\w{2,})(\>|$)/, 'Enter a valid email')
                    .required('Required'),
                number: Yup.string().matches(/^[6-9]\d{9}$/, 'Enter a valid mobile number')
                    .required('Required'),
                message: Yup.string()
                    .required('Required'),
            }),
            onSubmit: (values) => {
                let formData=new FormData();
                if (values.id) {
                    formData.append("id", values.id??"");
                }
                formData.append("name", values.name);
                formData.append("email", values.email);
                formData.append("number", values.number);
                formData.append("company_name", values.company_name);
                formData.append("message", values.message);
                SendEmail({ formData })((response) => {
                    console.log(response);
                    if (response&&response.status) {
    
                        console.log(response?.data?.data)
                        toast.success("Success", {
                            position: toast.POSITION.TOP_CENTER
                        });
                        formik.resetForm()
                          
                    }
                    else {
                        formik.setErrors({
                            email: response?.response?.data?.errors?.email,
                        });
                        console.log(response?.response?.data?.errors);
                        toast.error("Something went wrong", {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
    
                });
            },
        });
        console.log(formik.errors);

        // API
    return (
        <React.Fragment>
        <ToastContainer  className="toast-position"/>
            <Layout>
                <div className='contact' ref={bottomRef}>
                    <div className='banner'>
                        <div className='breadcrum'><h1><strong>Contact Us</strong></h1></div>
                        <div className='sub-down-arrow'  onClick={() => window.scrollTo(0, myRef.current.offsetTop)}>
                          <Link to=''>
                              <BsChevronDoubleDown />
                            </Link>
                       </div>
                    </div>
                    <div className='main-content'  ref={myRef}>
                        <div className='container'>
                            <Slide bottom>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='title'>
                                            <h2>Contact Us</h2>
                                            <p>Let us know about your questions or concerns. We will get back to you as soon as we can.</p>
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                            <Slide bottom>
                                <div className='content-wrapper'>
                                    <div className='row dv-center'>
                                        <div className='col-lg-2'>
                                            <div className='email-label'>
                                                <p><span className='img-wrapper'><img src={mail} className='img-fluid' alt="email_icon" /> </span> Email</p>
                                            </div>
                                        </div>

                                        <div className='col-lg-10'>
                                        <div className='row'>
                                        <div className='col-lg-3 col-md-6 p-0 mpt-10'>
                                            <div className='email-content brder'>
                                                <h5>Bryan Bruton</h5>
                                                <Link to='mailto:bryan@claimbridge.health'>bryan@claimbridge.health</Link>
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-6  p-0 mpt-10'>
                                            <div className='email-content brder'>
                                                <h5>Andy Emerson</h5>
                                                <Link to="mailto:andy@claimbridge.health">andy@claimbridge.health</Link>
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-6  p-0 mpt-10'>
                                            <div className='email-content brder '>
                                                <h5>Timothy McPherson</h5>
                                                <Link to='mailto:timothy@claimbridge.health'>timothy@claimbridge.health</Link>
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-6  p-0 mpt-10'>
                                            <div className='email-content brder r-brdr'>
                                                <h5>Mike Neal</h5>
                                                <Link to='mailto:mike@claimbridge.health'>mike@claimbridge.health</Link>
                                            </div>
                                        </div>
                                        </div>

</div>
                                        
                                    </div>
                                    <div className='row dv-center pt-20 '>
                                        <div className='col-lg-2'>
                                            <div className='email-label'>
                                                <p><span className='img-wrapper'><img src={call} className='img-fluid' alt="call_icon" /> </span> Phone</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-3 p-0 mpt-10'>
                                            <div className='email-content  full-brdr'>
                                                <Link className='call_text' to='tel:913-269-3400'>913-269-3400</Link>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='row dv-center pt-20'>
                                        <div className='col-lg-2'>
                                            <div className='email-label'>
                                                <p><span className='img-wrapper'><img src={location} className='img-fluid' alt="email_icon" /> </span> Location</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 p-0 mpt-10'>
                                            <div className='email-content full-brdr'>
                                                <h5>275 East Loch Lloyd Parkway Belton, MO 64012</h5>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </Slide>
                            <hr className='hrline' />
                            <div className='form-sec'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='title'>
                                            <h2>Write To Us</h2>
                                        </div>
                                    </div>
                                </div>
                                <Slide bottom>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='form'>
                                                <Form onSubmit={formik.handleSubmit}>
                                                    <div className='row'>
                                                        <div className='col-lg-6'>
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Company Name"
                                                                className="mb-3"
                                                                                                                           >
                                                                <Form.Control 
                                                                type="text"
                                                                 placeholder="name@example.com"
                                                                 name='company_name'
                                                                 value={formik.values?.company_name}
                                                                onChange={formik.handleChange}
                                                                isValid={formik.touched.company_name&&!formik.errors.company_name}
                                                                isInvalid={!!formik.errors.company_name}
                                                                  />
                                                                  <Form.Control.Feedback>{formik.errors.company_name}</Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        </div>
                                                        <div className='col-lg-6'>
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Name"
                                                                className="mb-3"
                                                                
                                                            >
                                                                <Form.Control 
                                                                type="text" 
                                                                placeholder="name@example.com"
                                                                name='name'
                                                                value={formik.values?.name}
                                                        onChange={formik.handleChange}
                                                        isValid={formik.touched.name&&!formik.errors.name}
                                                        isInvalid={!!formik.errors.name}
                                                                 />
                                                                 <Form.Control.Feedback>{formik.errors.name}</Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        </div>
                                                        <div className='col-lg-6'>
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Email"
                                                                className="mb-3"
                                                            >
                                                                <Form.Control
                                                                 type="email"
                                                                  placeholder="name@example.com" 
                                                                  name='email'
                                                                  value={formik.values?.email}
                                                        onChange={formik.handleChange}
                                                        isValid={formik.touched.email&&!formik.errors.email}
                                                        isInvalid={!!formik.errors.email}

                                                                  />
                                                                  <Form.Control.Feedback type='invalid'>{formik.errors.email}</Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        </div>
                                                        <div className='col-lg-6'>
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Mobile"
                                                                className="mb-3"
                                                            >
                                                                <Form.Control 
                                                                type="text"
                                                                 placeholder="name@example.com"
                                                                 name='number'
                                                                 value={formik.values?.number}
                                                        onChange={formik.handleChange}
                                                        isValid={formik.touched.number&&!formik.errors.number}
                                                        isInvalid={!!formik.errors.number}
                                                                  />
                                                                  <Form.Control.Feedback type='invalid'>{formik.errors.number}</Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        </div>

                                                        <div className='col-lg-12'>
                                                            <FloatingLabel controlId="floatingTextarea2" label="Message">
                                                                <Form.Control
                                                                    as="textarea"
                                                                    placeholder="Leave a comment here"
                                                                    style={{ height: '150px' }}
                                                                    name='message'
                                                                    value={formik.values?.message}
                                                        onChange={formik.handleChange}
                                                        isValid={formik.touched.message&&!formik.errors.message}
                                                        isInvalid={!!formik.errors.message}
                                                                />
                                                                <Form.Control.Feedback>{formik.errors.message}</Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        </div>
                                                    </div>
                                                    <div className='btn-wrapper'>
                                                        <Button variant="primary" className='contact-btn' type="submit">
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </React.Fragment>
    )
}

export default Contact;
