import React, { useEffect } from 'react'
import footer_logo from '../../assets/images/footer-logo.png'
import twitter from '../../assets/images/footer/twitter.svg'
import facebook from '../../assets/images/footer/facebook.svg'
import linkedin from '../../assets/images/footer/linkedin.svg'
import { Button, Form } from 'react-bootstrap';
import * as Yup from 'yup';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik'
import { ToastContainer, toast } from 'react-toastify'
import ScrollToTop from 'react-scroll-to-top'
import { newsLetter } from '../../context/actions/newsletter'
const Footer=() => {
    const currentYear=new Date().getFullYear();
    const navigate=useNavigate()
    const showToastMessage=(message) => {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    const location=useLocation();
    // useEffect(() => {
    //     const section=new URLSearchParams(location.search).get('section');
    //     if (section) {
    //         const activeSection=document.getElementById(section);
    //         if (activeSection) {
    //             const topOffset=activeSection.offsetTop;
    //             window.scrollTo({ top: topOffset, behavior: "smooth" });
    //         }
    //     }
    // }, [location]);


    // API
    const formik=useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().matches(/(\<|^)[\w\d._%+-]+@(?:[\w\d-]+\.)+(\w{2,})(\>|$)/, 'Enter a valid email')
                .required('Required'),
        }),
        onSubmit: (values) => {
            let formData=new FormData();
            if (values.id) {
                formData.append("id", values.id??"");
            }
            formData.append("email", values.email);
            newsLetter({ formData })((response) => {
                console.log(response);
                if (response&&response.status) {
                    console.log(response?.data?.data)
                    toast.success("Success", {
                        position: toast.POSITION.TOP_CENTER
                    });
                    formik.resetForm()
                }
                else {
                    formik.setErrors({
                        email: response?.response?.data?.errors?.email,
                    });
                    console.log(response?.response?.data?.errors);
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            });
        },
    });
    // API
    return (
        <React.Fragment>
            <ToastContainer className="toast-position" />
            <div className='footer'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='footer-left'>
                                <div className='logo-wrapper'>
                                    <img src={footer_logo} className='img-fluid' />
                                </div>

                                <div className='content-wrapper'>
                                    <p>The genesis for ClaimBridge was the opportunity to partner with an industry leading Electronic Health Record (EHR)
                                        company to provide Revenue Cycle Automation and Claim Payment Acceleration.</p>
                                    <Link to='/about'>Learn More</Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='footer-right'>
                                <div className="form">
                                    <Form onSubmit={formik.handleSubmit}>
                                        <div className='row'>
                                            <div className='col-sm-9 col-8 p0 plr-5' >
                                                <Form.Group className="" controlId="formBasicEmail">
                                                    <Form.Control
                                                        type="text"
                                                        name="email"
                                                        value={formik.values?.email}
                                                        onChange={formik.handleChange}
                                                        isValid={formik.touched.email&&!formik.errors.email}
                                                        isInvalid={!!formik.errors.email}
                                                        placeholder='Enter your email'
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className='col-sm-3 col-4 p0 plr-5'>
                                                <Button className='btn subscribe-btn' type='submit'>
                                                    Subscribe
                                                </Button>
                                            </div>
                                        </div>
                                    </Form>

                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div onClick={() => navigate('/', { state: { news: true } })} className='newsletter'>Sign up to recieve updates from us</div>
                                    </div>
                                </div>

                                <div className='footer-contact'>
                                    <div className='row'>
                                        <div className='col-md-7'>
                                            <div className='location'>
                                                <h6>Locate Us</h6>
                                                <p>275 East Loch Lloyd Parkway Belton, MO 64012</p>
                                                <Link to='tel:913-269-3400'>913-269-3400</Link>
                                            </div>
                                        </div>
                                        <div className='col-md-5'>
                                            {/* <div className='social-icons'>
                                                <h6>Follow us On</h6>
                                                <ul>
                                                    <li><Link to=''> <span className='img-wrapper'> <img src={facebook} className='img-fluid' /></span></Link></li>
                                                    <li><Link to=''>  <span className='img-wrapper'><img src={twitter} className='img-fluid' /></span></Link></li>
                                                    <li><Link to=''> <span className='img-wrapper'> <img src={linkedin} className='img-fluid' /></span></Link></li>
                                                </ul>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='copyright-sec'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <p>Copyright &copy; {currentYear} RetrieveRemit, LLC</p>
                            </div>
                            <div className='col-lg-6'>
                                <p className='txt-right'>Designed & Developed by <Link target='_blank' to='https://www.aventusinformatics.com/'>Aventus Informatics</Link></p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='sticky-btn'>
                <Link class="buttonLink" to="/contact"><button type="button" class="btn btn-large verticalButton">Contact Us</button></Link>
            </div>
            <ScrollToTop smooth top="1000" color="#fff" width="18" height="18" style={{ backgroundColor: "#19315B", bottom: "60px" }} />
        </React.Fragment>
    )
}

export default Footer;
