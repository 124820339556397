import React, { useEffect, useState } from 'react'
import "../assets/css/component-styles/news.scss";
import Layout from './layout';
import { Link } from 'react-router-dom';
import news1 from '../assets/images/home/news1.png'
import news2 from '../assets/images/home/news2.png'
import news3 from '../assets/images/home/news3.png'
import news4 from '../assets/images/home/news2.png'

const Allarticles = () => {


  return (
    <React.Fragment>
        <Layout>
            <div className='all-article news'>
            <div className='banner'>
                        <div className='breadcrum'><h1><strong></strong></h1></div>
                    </div>
                <div className='container'>
                <div className='row slider-wrapper-content'>
                      
                            <div className='col-lg-4 col-md-6 col-12 pt-20' >
                                <Link to='/articles' >
                                    <div className='slider-wrapper'>
                                        <div className='img-wrapper'>
                                            <img src={news1} alt='slider1' className='img-fluid' />
                                        </div>
                                        <div className='content'>
                                            <h5>Articles</h5>
                                            <h4>Becker’s Hospital review recently
                                                reported "Between increasing
                                                pressures and mounting expens pressures and mounting expens</h4>
                                            <p>FEB 21, 2023</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className='col-lg-4 col-md-6 col-12 pt-20'>
                                <Link to='/articles' >
                                    <div className='slider-wrapper'>
                                        <div className='img-wrapper'>
                                            <img src={news2} alt='slider1' className='img-fluid' />
                                        </div>
                                        <div className='content'>
                                            <h5>Articles</h5>
                                            <h4>Becker’s Hospital review recently
                                                reported "Between increasing
                                                pressures and mounting expens dsdad</h4>
                                            <p>FEB 21, 2023</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className='col-lg-4 col-md-6 col-12 pt-20'>
                                <Link to='/articles' >
                                    <div className='slider-wrapper'>
                                        <div className='img-wrapper'>
                                            <img src={news3} alt='slider1' className='img-fluid' />
                                        </div>
                                        <div className='content'>
                                            <h5>Articles</h5>
                                            <h4>Becker’s Hospital review recently
                                                reported "Between increasing
                                                pressures and mounting expens adadsdfsdf</h4>
                                            <p>FEB 21, 2023</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className='col-lg-4 col-md-6 col-12 pt-20'>
                                <Link to='/articles' >
                                    <div className='slider-wrapper'>
                                        <div className='img-wrapper'>
                                            <img src={news4} alt='slider1' className='img-fluid' />
                                        </div>
                                        <div className='content'>
                                            <h5>Articles</h5>
                                            <h4>Becker’s Hospital review recently
                                                reported "Between increasing
                                                pressures and mounting expens adaddsffds</h4>
                                            <p>FEB 21, 2023</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>


                    </div>
                </div>
            </div>
        </Layout>
    </React.Fragment>
  )
}

export default Allarticles;
