import React, { useEffect, useRef } from 'react'
import '../../assets/css/component-styles/home.scss';
import Layout from '../layout';
import Banner from './components/banner';
import Section2 from './components/section2';
import Section3 from './components/section3';
import Team from './components/team';
import News from './components/news';
import Clients from './components/clients';
import { useLocation } from 'react-router-dom';

const Index=() => {
    const bottomRef=useRef(null);
    const myRef =useRef()
    const newsRef =useRef()
    const {state} = useLocation()
    useEffect(() => {
        bottomRef.current?.scrollIntoView({ block: 'start' });
    }, [bottomRef.current]);
    useEffect(() => {
        console.log(state)
        if(state?.news){
            newsRef.current.scrollIntoView({ block: 'start' })
            window.history.replaceState({}, document.title)
        }
    }, [state]);

    return (
        <React.Fragment>
            <Layout>
                <div className='home-page' ref={bottomRef}>
                    <Banner myRef={myRef} />
                    <Section2 myRef={myRef} />
                    <Section3 />
                    <Team />
                    <News newsRef={newsRef} />
                    {/* <Clients /> */}
                </div>
            </Layout>
        </React.Fragment>
    )
}

export default Index;
