import React, { useEffect, useRef } from 'react'
import '../assets/css/component-styles/about.scss';
import { Link } from 'react-router-dom';
import logo from "../assets/images/logo.png";
import member1 from "../assets/images/home/team1.png";
import member2 from "../assets/images/home/team2.png";
import member3 from "../assets/images/home/team3.png";
import member4 from "../assets/images/about/team4.jpg";
import member5 from "../assets/images/about/team5.png";
import mask_img from '../assets/images/home/sec2-img.png'
import line from '../assets/images/home/line.png'
// import member6 from "../assets/images/about/team6.jpg";
import member7 from "../assets/images/about/team7.png";
import dummy from "../assets/images/about/dummy.png";
import linkedin from "../assets/images/about/linkedin.svg";
import Layout from "./layout";
import { Slide } from "react-reveal";
import { GrPrevious, GrNext } from 'react-icons/gr';
import {
  BsFillTelephoneFill,
  BsFillEnvelopeFill,
  BsGlobeAsiaAustralia,
  BsChevronDoubleDown,
} from "react-icons/bs";
import Slider from "react-slick";
import { getteamsListing } from '../context/actions/teams';

const About_main=() => {


    const bottomRef = useRef(null);
    const myRef = useRef(null);
    useEffect(() => {
      bottomRef.current?.scrollIntoView({ block: "start" });
    }, [bottomRef.current]);
  
   
  //  API 
    const [data, setData] = React.useState([]);
    useEffect(() => {
      getteamsListing()((response) => {
          if (response && response.status) {
              console.log(response);
              setData(response?.data?.data?.results)
          }
      });
  }, []);
  //  API   
  
    function SampleNextArrow(props) {
      const { onClick } = props;
      return (
          <div style={{ color: "green" }} onClick={onClick}><GrNext className='next_icon' /> </div>
      );
  }
  
  function SamplePrevArrow(props) {
      const { onClick } = props;
      return (
          <div style={{ color: "green" }} onClick={onClick}><GrPrevious className='prev_icon' /> </div>
      );
  }
  
    var settings={
      infinite: true,
      speed: 1000,
      slidesToShow:3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      initialSlide: 0,
      cssEase: "linear",
      responsive: [
          {
              breakpoint: 1024,
              settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
              }
          },
          {
              breakpoint: 991,
              settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  initialSlide: 2
              }
          },
          {
              breakpoint: 767,
              settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
              }
          }
      ]
  };
    return (
        <React.Fragment>
            <Layout>
                <div className='about abt-main' ref={bottomRef}>
                    <div className='sub-banner'>
                        <div className='breadcrum'><h1><strong>About Us</strong></h1></div>
                        <div className='sub-down-arrow'  onClick={() => window.scrollTo(0, myRef.current.offsetTop)}>
                          <Link to=''>
                              <BsChevronDoubleDown />
                            </Link>
                       </div>
                    </div>
                    <div  ref={myRef}></div>
                    <Slide bottom>
                    <div className='section2'>
                  
            <Slide bottom>
                <div className='container'>

                    <div className='sec2-wrap'>
                        <div className='row dv-center'>
                            <div className='col-lg-6'>
                                <div className='img-wrapper'>
                                    <img src={mask_img} alt='slider1' className='img-fluid c-img' />
                                    <img src={line} alt='slider1' className='img-fluid line-img' />
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='content-wrapper'>
                                    <h2>ClaimBridge</h2>
                                    <p>The genesis for ClaimBridge, a Retrieve Remit Company, was the opportunity to partner with an industry leading Electronic Health Record (EHR)
                                        company to provide Revenue Cycle Automation and Claim Payment Acceleration.  A survey of their clients showed overwhelmingly
                                        that the amount of time to get Claim reimbursed is the biggest pain point and making sense of the claim-to-cash data, both
                                        paper and electronic is a close second.</p>
                                  
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* // ecg line animation start */}
                    {/* <div class="intro-block d-none d-lg-block">

                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" >
                            <g>
                                <polyline class="ekg" points="486.6,113.8 328.2,113.8 310.3,132.3 296,70.7 246.8,127.4 241.6,120.2 233.9,166.4 227,27.6 
        213.2,118.3 211.8,112.3 205.1,126.1 198.2,108.5 194.1,124.4 184.5,92.9 174.1,113 4.3,113 	" />
                            </g>
                        </svg>

                    </div> */}
                    {/* // ecg line animation end */}

                </div>
                </Slide>
                </div>
                        <div className='main-content'>
                            <div className='container'>
                                <div className='row'>
                                    <p><span className='green-bg'> ClaimBridge </span>   was created to address both issues, in fact, the byproduct of processing Claim-to-cash transactions is the
                                        data required to power our Claim Payment Accelerator.  Traditionally the claim-to-cash has been handled manually using
                                        spreadsheets and staff members.  This approach can be made to work; however, in today’s environment staffing is a significant
                                        challenge, and manually processing inevitably leads to data leakage and inefficiencies.</p>
                                    <p>New technologies such as artificial intelligence (AI), machine learning (ML), and robotic process automation (RPA) have
                                        recently hit the market and can significantly enhance the claim-to-cash process by automating repetitive tasks, identifying
                                        patterns and trends, and improving accuracy and efficiency.  Adding AI-enhanced technology to the manual process of converting
                                        EOBs can add significant value by improving efficiency, accuracy, and freeing up staff time and resources.  An exciting
                                        by-product of this process is structured data required to power the Claim Accelerator the allows Provides to receive
                                        payments quickly and at a fraction of the cost of traditional methods such as Factoring or Lines of Credit.

                                    </p>
                                    <p>As the healthcare industry continues to evolve, providers that invest in advanced technologies to streamline their
                                        operations and improve their financial performance will remain competitive and well-positioned for long-term success.

                                    </p>
                                </div>
                            </div>
                        </div>
                    </Slide>
                    <div className="our-team">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="title text-center">
                      <h2>Our Team</h2>
                    </div>
                  </div>
                </div>

                <div className="row team-slider">
                <Slider {...settings}>

                {data?.map((value, index) =>
                            <div key={index}>

                  <div className=" plr-25">
                    <div className="team-content content">
                      <div className="member-details">
                        <div className="row">
                          <div className="col-12">
                            <div className="content-wrapper">
                              <div className="logo-wrapper">
                                <img
                                  src={logo}
                                  alt="slider1"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="profile">
                                <div className="pro_pic">
                                  <img
                                    src={value?.image}
                                    alt="slider1"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="name-group">
                                  <h5>{value?.name}</h5>
                                  <h6>{value?.designation}</h6>
                                </div>
                              </div>

                              <Link to={`tel:${value?.phone_number}`} className="phone">
                                <BsFillTelephoneFill /> {value?.phone_number}
                              </Link>
                              <Link
                                to={`mailto:${value?.email}`}
                                className="phone"
                              >
                                <BsFillEnvelopeFill /> {value?.email}
                              </Link>
                              <Link
                                to={value?.website}
                                target="_blank"
                                className="phone"
                              >
                                <BsGlobeAsiaAustralia className="mr-10"/>{value?.website}{" "}
                              </Link>

                              {value?.linkedin_profile_url &&
                              <div className="linkedin">
                                <Link to={value?.linkedin_profile_url} target="_blank">
                                  {" "}
                                  <img
                                    src={linkedin}
                                    alt="slider1"
                                    className="img-fluid"
                                  />
                                </Link>
                              </div>
                              }
                           


                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="text-content">
                            <p>
                            {value?.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                  )}
               
                  </Slider>
                </div>

              </div>
            </div>
                </div>
            </Layout>
        </React.Fragment>
    )
}

export default About_main;
