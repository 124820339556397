import { SEND_EMAIL } from "../../../constants/api";
import axiosInstance from "../../../helpers/axiosInstance";






export const SendEmail=(props) => onResponse => {

    try {
        axiosInstance.post(SEND_EMAIL, props.formData)
            .then((response) => {
                onResponse(response);
            })
            .catch((err) => {
                onResponse(err.response);
            });

    } catch (error) {

    }
};








