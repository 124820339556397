import './assets/css/style.scss';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/home/index'
import About from './pages/about'
import Articles from './pages/articles';
import Rca from './pages/services/rca';
import Cpa from './pages/services/cpa';
import Contact from './pages/contact';
import Main_cpa from './pages/services/main_cpa';
import Main_rca from './pages/services/main_rca';
import About_main from './pages/about_main';
import Allarticles from './pages/allarticles';
import Error from './pages/error';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/about' element={<About />} />
        <Route exact path='/about_main' element={<About_main />} />
        <Route exact path='/articles/:id' element={<Articles />} />
        <Route exact path='/Revenue_Cycle_Automation' element={<Rca />} />
        <Route exact path='/Claims_Payment_Acceleration' element={<Cpa />} />
        <Route exact path='/Main_Claims_Payment_Acceleration' element={<Main_cpa />} />
        <Route exact path='/Main_Revenue_Cycle_Automation' element={<Main_rca />} />
        <Route exact path='/contact' element={<Contact />} />
        <Route exact path='/All-Articles' element={<Allarticles />} />
        <Route exact path='/*' element={<Error />} />
            

      </Routes>
    </BrowserRouter>
  );
}

export default App;
