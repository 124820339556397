import React from 'react'
import { Link } from 'react-router-dom';
import Slide from 'react-reveal/Slide';
import down_arrow from '../../../assets/images/triple-down-arrow.svg'
import { useRef } from 'react';
import { useEffect } from 'react';
import {BsChevronDoubleDown} from "react-icons/bs";
const Banner=(props) => {

    // const bottomRef = useRef(null);
    // useEffect(() => {
    //     // bottomRef.current?.scrollIntoView({ block: "start" });
    // }, []);
    const {myRef} = props;


    return (
        <div className='banner'>
            <div className='overlay'></div>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6'>

                    </div>
                    <div className='col-lg-6'>
                        <Slide bottom>
                            <div className='banner-right'>
                                <h3>Claim Payment Acceleration:</h3>
                                <h1>Unlock the time value of
                                    quicker claim payments</h1>
                                <h6>ClaimBridge specializing in the Healthcare Fintech industry.</h6>
                                <Link className='btn banner-btn' to='/Main_Claims_Payment_Acceleration'>Learn More</Link>
                            </div>
                            
                            <div className='down-arrow'>
                            <div onClick={() => myRef.current.scrollIntoView()}>
                               <BsChevronDoubleDown />
                                </div>
                            </div>
                        </Slide>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner;
