import React, { useEffect, useRef } from 'react'
import '../../assets/css/component-styles/rca.scss';
import Layout from '../layout';
import { Link } from 'react-router-dom';
import icon1 from '../../assets/images/services/icon1.png'
import icon2 from '../../assets/images/services/icon2.png'
import icon3 from '../../assets/images/services/icon3.png'
import icon4 from '../../assets/images/services/icon4.png'
import Slide from 'react-reveal/Slide';
import { BsChevronDoubleDown } from 'react-icons/bs';
const Rca=() => {
    const bottomRef=useRef(null);
    const myRef = useRef(null);
    useEffect(() => {
        bottomRef.current?.scrollIntoView({ block: 'start' });
    }, [bottomRef.current]);

    return (
        <React.Fragment>
            <Layout>
                <div className='rca' ref={bottomRef}>
                    <div className='sub-banner'>
                        <div className='overlay'></div>
                        <div className='breadcrum'><h1><strong>Revenue
                            Cycle Automation</strong></h1></div>
                            <div className='sub-down-arrow'  onClick={() => window.scrollTo(0, myRef.current.offsetTop)}>
                          <Link to=''>
                              <BsChevronDoubleDown />
                            </Link>
                       </div>
                    </div>
                    <div className='main-content'  ref={myRef}>
                        <div className='container'>
                            <div className='row'>
                                <Slide bottom>
                                <div className='listing-wrapper'>
                                   <h6 className='pb-15'>Payments/Remittance Advice/Correspondence</h6>
                                   <h6 className='pb-15'>There are several ways Payers communicate adjudicated claims back to the Providers</h6>
                                   <p>Electronic Remittance Advice (ERA) – The preferred method due to efficiency </p>
                                   <ul>
                                    <li>Native 835s – Remittance Advice</li>
                                    <li>ACH – payment</li>
                                   </ul>
                                   <p>Paper – A perennial problem for Providers due to inefficiencies and difficulty reconciling, storing data, and reporting</p>
                                   <ul>
                                    <li> Paper Checks</li>
                                    <li> Explanation of Benefits (EOB)</li>
                                    <li> Correspondence – Not a payment or remittance advice, can require timely follow-up to process claims properly.  A couple of examples are denials or requests for more information</li>
                                   </ul>
                                   <h6>Complications due to data arrival patterns</h6>
                                   <ul>
                                    <li>Payments and Remittance Advice arrive via email, Payer portals, and USPS (paper files)</li>
                                    <li>The arrival patterns are generally asymmetrical</li>
                                    <li>The mismatches in data create inefficiencies and loss of revenue</li>
                                   </ul>
                                   <h6>How many Providers currently solve the issue</h6>
                                   <ul>
                                    <li>Psychedelic Spreadsheets – multi-page spreadsheets or multiple spreadsheets (Psychedelic due to the color-coding required to manage multiple lines of data)</li>
                                    <li>Fully or primarily manual matching</li>
                                    <li>Manual Posting to the PAS</li>
                                   </ul>
                                   <p>By partnering with ClaimBridge, we will automate much of the claim-to-cash cycle.  We will convert the unstructured data from paper files into structured electronic files.  Not only will efficiency improve, but the by-product of automation is that structured data can be used for value-enhancing purposes. </p>
                                   </div>
                                </Slide>
                            </div>
                            <div className='content-section'>
                                <Slide bottom>
                                    <div className='row'>
                                        <div className='col-lg-3 col-sm-6 mbt-20'>
                                            <div className='content-wrapper plr-20'>
                                                <div className='img-wrapper'>
                                                    <img src={icon1} alt='slider1' className='img-fluid' />
                                                </div>
                                                <h5> Cost: </h5>
                                                <p>Manually processing cash-to-claim remittance processing in-house can be more expensive due to the need to hire and train staff, purchase equipment, and maintain the necessary infrastructure to handle the process. Outsourcing the image conversion process to a third-party service provider can be a more cost-effective option since the service provider already has the necessary infrastructure and expertise in place.</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-sm-6 mbt-20'>
                                            <div className='content-wrapper plr-20'>
                                                <div className='img-wrapper'>
                                                    <img src={icon2} alt='slider1' className='img-fluid' />
                                                </div>
                                                <h5> Accuracy: </h5>
                                                <p>Manually processing cash-to-claim remittance processing can be more prone to errors due to the risk of human mistakes in data entry and processing. Outsourcing to a third-party service provider can provide higher accuracy levels as they use advanced technologies like AI and OCR to capture and convert data.</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-sm-6 mbt-20'>
                                            <div className='content-wrapper plr-20'>
                                                <div className='img-wrapper'>
                                                    <img src={icon3} alt='slider1' className='img-fluid' />
                                                </div>
                                                <h5>Turnaround time: </h5>
                                                <p>Manually processing cash-to-claim remittance processing can take longer due to the manual labor involved, which can result in delayed payments to healthcare providers. Outsourcing to a third-party service provider can provide faster turnaround times as they have the necessary tools and expertise to process Claim quickly.</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-sm-6 mbt-20'>
                                            <div className='content-wrapper plr-20'>
                                                <div className='img-wrapper'>
                                                    <img src={icon4} alt='slider1' className='img-fluid' />
                                                </div>
                                                <h5>Scalability: </h5>
                                                <p>Manually processing cash-to-claim remittance processing in-house may not be scalable to accommodate changes in volume or growth in the business. Outsourcing to a third-party service provider can provide scalability to handle changes in volume or growth in the business</p>
                                            </div>
                                        </div>
                                    </div>
                                </Slide>
                                <Slide bottom>
                                    <div className='row'>
                                        <p className='pt-25'>Overall, outsourcing the image conversion process for cash-to-claim remittance processing can provide healthcare providers with cost savings, higher accuracy levels, faster turnaround times, and scalability. This can help providers improve their financial performance and provide better care for their patients.</p>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                    </div>

                </div>
            </Layout>
        </React.Fragment>
    )
}

export default Rca;
