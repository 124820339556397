import React from "react";
import Layout from "../layout";
import { useEffect } from "react";
import { useRef } from "react";
import icon1 from '../../assets/images/services/icon1.png'
import icon2 from '../../assets/images/services/icon2.png'
import icon3 from '../../assets/images/services/icon3.png'
import icon5 from '../../assets/images/services/icon5.png'
import icon6 from '../../assets/images/services/icon6.png'
import icon7 from '../../assets/images/services/icon7.png'
import list1 from '../../assets/images/home/utlize.png'
import list2 from '../../assets/images/home/systems.png'
import list3 from '../../assets/images/home/automation.png'
import dotted_line from '../../assets/images/home/dotted-line.png'
import Slide from 'react-reveal/Slide';
import { Link } from "react-router-dom";
import { BsChevronDoubleDown } from "react-icons/bs";
const Main_cpa = () => {
  const bottomRef=useRef(null);

  const myRef = useRef(null);

  useEffect(() => {
      bottomRef.current?.scrollIntoView({ block: 'start' });
  }, [bottomRef.current]);
  return (
    <React.Fragment>
      <Layout>
    
      <div className='rca' ref={bottomRef}>
                    <div className='cpa-banner'>
                        <div className='overlay'></div>
                        <div className='breadcrum'><h1><strong>Claim Payment Accelerator </strong></h1></div>
                        <div className='sub-down-arrow'  onClick={() => window.scrollTo(0, myRef.current.offsetTop)}>
                          <Link to=''>
                              <BsChevronDoubleDown />
                            </Link>
                       </div>
                    </div>

                    <div className='section3 mt-50'  ref={myRef}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='left-content-wrapper'>
                                <h2>Claim Payment
                                    Acceleration</h2>
                                <h6>What does the Claim Payment Accelerator do?</h6>
                                <ul>
                                    <li>Claim to cash data (Claim & remittance advice) is used to
                                        power sophisticated algorithms, where we rate a Claims’ propensity
                                        to pay and predict the speed of payment</li>
                                    <li>Because we are able to accurately model the behavior of the claim,
                                        we are able reimburse the Provider prior to Payor claim adjudication</li>
                                    <li>Unlike industry standards for financing (Line of Credit or Factoring),
                                        using the Claim Accelerator will allow us to pre-pay Providers on a
                                        selected Claim quickly, with a target of the next business day after
                                        submission.  This is at a fraction of the cost of other financing options</li>
                                    <li>Claim Acceleration avoids one of the major pitfalls of using a Line
                                        of Credit (LOC) or Factoring, the exorbitant cost</li>
                                    <li>Unlike LOCs or Factoring, if we received more than expected, we
                                        provide bi-directional recourse, meaning if we underestimate a claim
                                        and it is reimbursed at a higher level than expected, we return the
                                        overage to the Provider </li>

                                </ul>
                                                           
                            </div>
                        </div>
                        <div className='col-lg-6'>

                            <div className='right-content-wrapper'>
                                <div className='row'>
                                    <div className='col-lg-3'> </div>
                                    <div className='col-lg-9'>
                                        <h5>How does the
                                            Claim Accelerator
                                            work?
                                        </h5>
                                    </div>
                                </div>
                                <div className='content1 contents'>
                                    <div className='row dv-center'>
                                        <div className='col-lg-3 col-4 txt-right'>
                                            <Slide bottom>
                                                <img src={list1} alt='slider1' className='img-fluid' />
                                            </Slide>
                                        </div>
                                        <div className='col-lg-9 col-8'>
                                            <p>We utilize your claim history to power the
                                                Claim Payment Accelerator.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='content2 contents'>
                                    <div className='row dv-center'>
                                        <div className='col-lg-3 col-4 txt-right'>
                                            <Slide bottom>
                                                <img src={list2} alt='slider1' className='img-fluid' />
                                            </Slide>
                                        </div>
                                        <div className='col-lg-9 col-8'>
                                            <p>Our systems are bank agnostic; therefore, we can
                                                take files from any bank, lockbox, or current vendor
                                                you may be currently using</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='content3 contents'>
                                    <div className='row dv-center'>
                                        <div className='col-lg-3 col-4 txt-right'>
                                            <Slide bottom>
                                                <img src={list3} alt='slider1' className='img-fluid' />
                                            </Slide>
                                        </div>
                                        <div className='col-lg-9 col-8'>
                                            <p>However, if you do not have a current automated
                                                solution, and your team is manually performing the
                                                claim-to-cash reconciliation, we can also help
                                                automate your process</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='dotted-line d-none d-lg-block'>
                    <img src={dotted_line} alt='slider1' className='img-fluid' />
                </div> */}
            </div>
                    <div className='main-content '>
                        <div className='container'>
                            <div className='row'>
                                <Slide bottom>
                                    <p><span className='green-bg'>Claim Payment Accelerator  </span> </p>
                                    <p>Let’s face it, Healthcare Providers deal with significant difficulties trying to get compensated. 
                                     None of which, is more challenging than the time it takes from rendering service to receiving payment from the
                                      Health Insurance company.  For larger hospital groups this delay may be nothing more than an inconvenience, 
                                      however, for smaller hospitals and physicians this delay could be the difference between being able to make 
                                      payroll on time.  So, what are the options?  Some delays may be minimized by insuring complete and accurate
                                       Claim are sent in the first place.  Services such as contract management and claim scrubbing may be outsourced,
                                        however, a Provider must carefully weigh the return on investment (ROI) of the decreasing delays and/or denials,
                                         vs the cost of paying for these services.   Other options are on the backend, post-claim adjudication, such as a
                                          line of credit at the bank, or selling the claim at a much-reduced rate known as factoring.  While both these 
                                          options can work, they are prohibitively expensive, especially in today’s economy further compounded by spiraling 
                                          inflation.</p>
                             <p>Fortunately, there is a better solution.  ClaimBridge’s Claim Payment Accelerator solves the issue by utilizing data that 
                             is the byproduct of automating the claim-to-payment revenue cycle.  We use the latest technology and a patent-pending decisions
                              engine that allows Claim to be rated by both their propensity to pay and the average number of days to receive payment.  
                              That means the Provider gets to choose how few or as many Claim they accelerate to meet cashflow needs.  For example, prior 
                              authorizations have a very high likelihood of payment and a well-established number of outstanding days.  Therefore, these are
                               the types of Claim that present less risk, and consequently less cost to Providers.  Best of all, if after rating a claim,
                                should the payment be higher than expected, ClaimBridge does not keep the upside we refund the Provider.</p>
                               <p className="blue-para">ClaimBridge makes it easy, as Healthcare providers can choose to get paid in 1-2 business days after a claim is submitted via ACH directly to their bank account.</p>
                                </Slide>
                            </div>
                            <div className='content-section'>
                                <Slide bottom>
                                    <div className='row'>
                                        <div className='col-lg-4 col-sm-6 mbt-20     '>
                                            <div className='content-wrapper plr-30'>
                                                <div className='img-wrapper'>
                                                    <img src={icon1} alt='slider1' className='img-fluid' />
                                                </div>
                                                <h5> Cost: </h5>
                                                <p>ClaimBridge ranks Claim by their propensity to pay.  Therefore, it can offer lower fees on those Claim that have a very low denial rate.  This ranking can be used to allow Providers to decide which Claim to accelerate to reach the cashflow needs for the lowest possible cost of funds</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-sm-6 mbt-20'>
                                            <div className='content-wrapper plr-30'>
                                                <div className='img-wrapper'>
                                                    <img src={icon3} alt='slider1' className='img-fluid' />
                                                </div>
                                                <h5>Turnaround time: </h5>
                                                <p>ClaimBridge can pay the provider in 1-2 business days after claim submission directly to the provider or via the clearing house</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-sm-6 mbt-20'>
                                            <div className='content-wrapper plr-30'>
                                                <div className='img-wrapper'>
                                                    <img src={icon5} alt='slider1' className='img-fluid' />
                                                </div>
                                                <h5>Cashflow: </h5>
                                                <p>ClaimBridge can provide a much larger amount for a claim than a Line of Credit from a financial institution will provide.  Generally, 2x or more</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-sm-6 mbt-20'>
                                            <div className='content-wrapper plr-30'>
                                                <div className='img-wrapper'>
                                                    <img src={icon6} alt='slider1' className='img-fluid' />
                                                </div>
                                                <h5> Accuracy: </h5>
                                                <p>ClaimBridge strives to be extremely precise in their prediction of what the claim will pay and provides bi-directional recourse should the amount be more or less than expected</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-sm-6 mbt-20'>
                                            <div className='content-wrapper plr-30'>
                                                <div className='img-wrapper'>
                                                    <img src={icon7} alt='slider1' className='img-fluid' />
                                                </div>
                                                <h5>Automation: </h5>
                                                <p>Automating the claim-to-cash revenue cycle creates the data required to feed the Accelerator</p>
                                            </div>
                                        </div>
                                    </div>
                                </Slide>
                                <Slide bottom>
                                    <div className='row'>
                                        <p className='pt-25'>Overall, the medical revenue cycle has several hurdles to overcome, ClaimBridge provides a clear path to automating the menial tasks and structuring the data in such a way that Providers can get paid sooner and at a lower cost than traditional options.</p>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                    </div>

                </div>
      </Layout>
    </React.Fragment>
  );
};

export default Main_cpa;
