import React, { useEffect } from 'react'
import '../../../assets/css/component-styles/news.scss';
import { Link, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import news1 from '../../../assets/images/home/news1.png'
import news2 from '../../../assets/images/home/news2.png'
import news3 from '../../../assets/images/home/news3.png'
import news4 from '../../../assets/images/home/news2.png'
import { Slide } from 'react-reveal';
import { getarticleListing } from '../../../context/actions/articles';
import moment from 'moment/moment';




const News=(props) => {
    const navigate = useNavigate();
    const onClickArticle = ()=>{
        getarticleListing()((response) => {
            if (response && response.status) {
                navigate('/articles/'+response?.data?.data?.results[0]?.id)
            }
          });
    }


const {newsRef} = props;
    const [data, setData] = React.useState([]);

    useEffect(() => {
        getarticleListing()((response) => {
            if (response && response.status) {
                console.log(response);
                setData(response?.data?.data?.results ? response?.data?.data?.results : [])
            }
        });
    }, []);
    

    var settings={
        infinite: false,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        initialSlide: 0,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    console.log(data);
    return (
        <div className='news' id='News'>
        <div ref={newsRef}></div>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='title'>
                            <h4>Insights & Articles</h4>
                            <h2>Industry News</h2>
                        </div>
                    </div>
                    <div className='col-lg-6 t-right'>
                        <div className='btn-wrapper'>
                        {console.log('')}
                            <Link onClick={()=>onClickArticle()} className='btn prim-btn'>View All</Link>
                        </div>
                    </div>
                </div>

                <div className='slider-wrapper-content'>
                    <div className='row'>
                        <Slider {...settings}>
                        {data?.map((value, index) =>
                            <div key={index}>
                                <Link to={'/articles/'+value?.id} >
                                    <div className='slider-wrapper'>
                                        <div className='img-wrapper'>
                                            <img src={value?.blog_image} alt={value?.title} className='img-fluid' />
                                        </div>
                                        <div className='content'>
                                            {/* <h5>{value?.title}</h5> */}
                                           <h4>{value?.title}</h4>
                                           <h5> <div className='description' dangerouslySetInnerHTML={{__html: value?.description}}/></h5>
                                            <p>{moment(value?.date).format('ll')}</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            )}
                            {/* <div>
                                <Link to='/articles' >
                                    <div className='slider-wrapper'>
                                        <div className='img-wrapper'>
                                            <img src={news2} alt='slider1' className='img-fluid' />
                                        </div>
                                        <div className='content'>
                                            <h5>Articles</h5>
                                            <h4>Becker’s Hospital review recently
                                                reported "Between increasing
                                                pressures and mounting expens dsdad</h4>
                                            <p>FEB 21, 2023</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div>
                                <Link to='/articles' >
                                    <div className='slider-wrapper'>
                                        <div className='img-wrapper'>
                                            <img src={news3} alt='slider1' className='img-fluid' />
                                        </div>
                                        <div className='content'>
                                            <h5>Articles</h5>
                                            <h4>Becker’s Hospital review recently
                                                reported "Between increasing
                                                pressures and mounting expens adadsdfsdf</h4>
                                            <p>FEB 21, 2023</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div>
                                <Link to='/articles' >
                                    <div className='slider-wrapper'>
                                        <div className='img-wrapper'>
                                            <img src={news4} alt='slider1' className='img-fluid' />
                                        </div>
                                        <div className='content'>
                                            <h5>Articles</h5>
                                            <h4>Becker’s Hospital review recently
                                                reported "Between increasing
                                                pressures and mounting expens adaddsffds</h4>
                                            <p>FEB 21, 2023</p>
                                        </div>
                                    </div>
                                </Link>
                            </div> */}


                        </Slider>
                    </div>
                </div>



            </div>
        </div>
    )
}

export default News;
