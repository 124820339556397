import React, { useEffect, useRef } from 'react'
import '../../../assets/css/component-styles/team.scss';
import { Card } from 'react-bootstrap';
import member1 from '../../../assets/images/home/team1.png'
import member2 from '../../../assets/images/home/team2.png'
import member3 from '../../../assets/images/home/team3.png'
import member4 from '../../../assets/images/about/team4.jpg'
import member5 from '../../../assets/images/about/team5.png'
import member6 from '../../../assets/images/about/team7.png'
import dummy from '../../../assets/images/about/dummy.png'
import { Link, useNavigate } from 'react-router-dom';
import { Slide } from 'react-reveal';
import Slider from 'react-slick';
import { GrPrevious, GrNext } from 'react-icons/gr';
import { getteamsListing } from '../../../context/actions/teams';

const Team=() => {
const navigate=useNavigate()

const [data, setData] = React.useState([]);

useEffect(() => {
    getteamsListing()((response) => {
        if (response && response.status) {
            console.log(response);
            setData(response?.data?.data?.results)
        }
    });
}, []);

    function SampleNextArrow(props) {
        const { onClick } = props;
        return (
            <div style={{ color: "green" }} onClick={onClick}><GrNext className='next_icon' /> </div>
        );
    }
    
    function SamplePrevArrow(props) {
        const { onClick } = props;
        return (
            <div style={{ color: "green" }} onClick={onClick}><GrPrevious className='prev_icon' /> </div>
        );
    }
    
      var settings={
        infinite: true,
        speed: 1000,
        slidesToShow:3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        initialSlide: 0,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

const onclickMoredetails=()=>{
    navigate('/about',{state:{from_details:true}})
}

console.log(data);

    return (
        <div className='team'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 text-center'>
                        <div className='title'>
                            <h2>Our Leadership Team</h2>
                        </div>
                    </div>
                </div>
                <Slide bottom>
                    <div className='main-content home-team-slider'>
                        <div className='row '>
                        <Slider {...settings}>

                         {data?.map((value, index) =>
                            <div key={index}>

                            <div className='team-wrap'>
                                <Card>
                                    <Card.Body>
                                        <div className='img-wrapper'>
                                            <img src={value?.image} alt='slider1' className='img-fluid' />
                                        </div>
                                        <div className='content-wrapper'>
                                            <h5>{value?.name}</h5>
                                            <h6>{value?.designation}</h6>
                                            <p> <Link to={`mailto:${value?.email}`} className='mailid'>{value?.email}</Link></p>
                                            <p> <Link to={`tel:${value?.phone_number}`} className='phone'>{value?.phone_number}</Link></p>
                                            <div className='btn-wrapper'>
                                                <div onClick={onclickMoredetails}   className='btn prim-btn'>More Details</div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            </div>
                            )}
                            </Slider>
                        </div>
                    </div>
                </Slide>
            </div>
        </div>
    )
}

export default Team;
