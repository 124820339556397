import React from 'react'
import { Container, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png'
import { getarticleListing } from '../../context/actions/articles';

const Header=() => {
    const [articles, setArticles] = React.useState([]);
    const navigate = useNavigate();
    const onClickArticle = ()=>{
        getarticleListing()((response) => {
            if (response && response.status) {
                navigate('/articles/'+response?.data?.data?.results[0]?.id)
            }
          });
    }
    return (
        <div className='header'>
            <Navbar expand="lg">
                <Container >
                    <Navbar.Brand href="/"><img src={logo} className="" alt="Earthood" id='header_image' /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className=" my-2 my-lg-0"
                            navbarScroll={true}

                        >
                            <Link className='nav-link' to="/about_main">About</Link>
                            <NavDropdown title="Solutions" className='drop-head' href="#Solutions" id="navbarScrollingDropdown" renderMenuOnMount={true}>
                                <Link className='dropdown-item' to="/Main_Claims_Payment_Acceleration ">Claim Payment Acceleration</Link>
                                <Link className='dropdown-item' to="/Main_Revenue_Cycle_Automation">Revenue Cycle Automation</Link>

                            </NavDropdown>
                            <div className='nav-link' onClick={()=>onClickArticle()}>Articles</div>
                            <Link className='nav-link' to="/contact">Contact Us</Link>
                        </Nav>

                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default Header;
