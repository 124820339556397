import React from 'react'
import { Link } from 'react-router-dom';
import list1 from '../../../assets/images/home/utlize.png'
import list2 from '../../../assets/images/home/systems.png'
import list3 from '../../../assets/images/home/automation.png'
import dotted_line from '../../../assets/images/home/dotted-line.png'
import Slide from 'react-reveal/Slide';
const Section3=() => {
    return (
        <React.Fragment>
            <div className='section3'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='left-content-wrapper'>
                                <h2>Claim Payment
                                    Acceleration</h2>
                                <h6>What does the Claim Payment Accelerator do?</h6>
                                <ul>
                                    <li>Claim to cash data (Claim & remittance advice) is used to
                                        power sophisticated algorithms, where we rate a Claims’ propensity
                                        to pay and predict the speed of payment</li>
                                    <li>Because we are able to accurately model the behavior of the claim,
                                        we are able reimburse the Provider prior to Payor claim adjudication</li>
                                    <li>Unlike industry standards for financing (Line of Credit or Factoring),
                                        using the Claim Accelerator will allow us to pre-pay Providers on a
                                        selected Claim quickly, with a target of the next business day after
                                        submission.  This is at a fraction of the cost of other financing options</li>
                                    <li>Claim Acceleration avoids one of the major pitfalls of using a Line
                                        of Credit (LOC) or Factoring, the exorbitant cost</li>
                                    <li>Unlike LOCs or Factoring, if we received more than expected, we
                                        provide bi-directional recourse, meaning if we underestimate a claim
                                        and it is reimbursed at a higher level than expected, we return the
                                        overage to the Provider </li>

                                </ul>
                                <div className='btn-wrapper'>
                                    <Link to='/Claims_Payment_Acceleration' className='btn prim-btn'>Learn More</Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>

                            <div className='right-content-wrapper'>
                                <div className='row'>
                                    <div className='col-lg-3'> </div>
                                    <div className='col-lg-9'>
                                        <h5>How does the
                                            Claim Accelerator
                                            work?
                                        </h5>
                                    </div>
                                </div>
                                <div className='content1 contents'>
                                    <div className='row dv-center'>
                                        <div className='col-lg-3 col-4 txt-right'>
                                            <Slide bottom>
                                                <img src={list1} alt='slider1' className='img-fluid' />
                                            </Slide>
                                        </div>
                                        <div className='col-lg-9 col-8'>
                                            <p>We utilize your claim history to power the
                                                Claim Payment Accelerator.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='content2 contents'>
                                    <div className='row dv-center'>
                                        <div className='col-lg-3 col-4 txt-right'>
                                            <Slide bottom>
                                                <img src={list2} alt='slider1' className='img-fluid' />
                                            </Slide>
                                        </div>
                                        <div className='col-lg-9 col-8'>
                                            <p>Our systems are bank agnostic; therefore, we can
                                                take files from any bank, lockbox, or current vendor
                                                you may be currently using</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='content3 contents'>
                                    <div className='row dv-center'>
                                        <div className='col-lg-3 col-4 txt-right'>
                                            <Slide bottom>
                                                <img src={list3} alt='slider1' className='img-fluid' />
                                            </Slide>
                                        </div>
                                        <div className='col-lg-9 col-8'>
                                            <p>However, if you do not have a current automated
                                                solution, and your team is manually performing the
                                                claim-to-cash reconciliation, we can also help
                                                automate your process</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='dotted-line d-none d-lg-block'>
                    <img src={dotted_line} alt='slider1' className='img-fluid' />
                </div>
            </div>

            <div className='section4'>
                <div className='container'>
                    <div className='row'>
                        <Slide bottom>
                            <div className='content-wrapper'>
                                <h2>Revenue Cycle Automation</h2>
                                <ul>
                                    <li>Conversion of Paper EOB remittances to an electronic manufactured 835s</li>
                                    <li>Reassociation of Payment and Remittance (835) files for GAAP compliance</li>
                                    <li>Customizable business rules to allow output files to be automatically posted into customers Patient Accounting Systems (PAS)</li>
                                    <li>Indexing and Categorization of Paper Letter Correspondence to record and streamline actionable data for higher claim recovery</li>
                                    <li>One fully HIPAA compliant system to store data, do research, and run reports in the form of client facing portal</li>
                                </ul>
                                <div className='btn-wrapper'>
                                    <Link to='/Revenue_Cycle_Automation' className='btn prim-btn'>Learn More</Link>
                                </div>
                            </div>
                        </Slide>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Section3;
