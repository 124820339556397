import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../assets/images/error/logo.png'
import bg from '../assets/images/error/error-bg.png'
import '../assets/css/component-styles/error.scss';

const Error=() => {
    return (
        <div className='error-page'>
            <div className='container'>
                <div className='logo-sec'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='img-wrapper'>
                             <Link to='/'>   <img src={logo} alt='logo' className='img-fluid' /></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='content-wrapper'>
                    <div className='row d-center mb-reverse'>
                       
                        <div className='col-md-6'>
                            <div className='img-wrapper'>
                                <img src={bg} alt='logo' className='img-fluid' />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <h3>Whoops!
                                Something went
                                Wrong</h3>
                            <p>The page you are looking for isn’t available.
                                Please return to Home page</p>
                            <div className='btn-wrapper'>
                                <Link to='/' className='btn error-btn'><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="icn bi bi-house-fill" viewBox="0 0 16 16"><path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L8 2.207l6.646 6.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5Z"></path><path d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6Z"></path></svg> GO HOME</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Error;
