import { TEAMS } from "../../../constants/api";
import axiosInstance from "../../../helpers/axiosInstance";






export const getteamsListing=() => onResponse => {

    try {
        axiosInstance.get(TEAMS)
            .then((response) => {
                onResponse(response);
            })
            .catch((err) => {
                onResponse(err.response);
            });

    } catch (error) {

    }
};







