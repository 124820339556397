import axios from 'axios';
import { reactLocalStorage } from 'reactjs-localstorage';

let headers={};

const devApiUrlEnv=process.env.REACT_APP_LOCAL_SERVER_URL;

const prodApiUrlEnv=process.env.REACT_APP_PROD_SERVER_URL;

// const BACKEND_URL=process.env.REACT_APP_DEBUG_ENV=="true"? devApiUrlEnv:prodApiUrlEnv;
// const BACKEND_URL = process.env.REACT_APP_PROD_SERVER_URL
// const BACKEND_URL = 'https://irm-api.aventusinformatics.com/api/'
// const BACKEND_URL = 'http://192.168.18.99:8000/api/'
// console.log('===>>>>>>>>>', BACKEND_URL, '===<<<<<<<<<<<<<', 'instance');

const BACKEND_URL='https://claimbridge-cms.aventusinformatics.com/api/'
// const BACKEND_URL='https://api.claimbridge.health/api/'


const axiosInstance=axios.create({
    baseURL: BACKEND_URL,
    headers
});

axiosInstance.interceptors.request.use(
    async (config) => {
        const token=reactLocalStorage.get('token');
        if (token) {
            config.headers.Authorization=`Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

axiosInstance.interceptors.response.use(
    (response) =>
        new Promise((resolve, reject) => {
            resolve(response);
        }),
    (error) => {
        if (!error.response) {
            return new Promise((resolve, reject) => {
                reject(error);
            });
        }

        if (error.response.status===403) {

        } else {
            return new Promise((resolve, reject) => {
                reject(error);
            });
        }
    },
);

export default axiosInstance;